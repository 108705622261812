<template>
  <div class="bet-user-container">
    <div class="bet-user__input is-hidden-mobile">
      <div ref="input" class="bet-user__input__title">
        Monto total a apostar
      </div>
      <div class="bet-user__input__box">
        <span>$ </span>
        <input ref="input" v-model="betAmount" type="text" @blur="validateAmount" @click="selectAllAmount" />
        <span>mxn</span>
      </div>
    </div>
    <div class="bet-user__title">
      ¿Qué camino quieres explorar?
    </div>
    <div class="bet-user__body">
      <div class="bet-user__option">
        <button class="bet-user__option__button" @click="nextStep('free')">Usuario Free</button>
        <div class="bet-user__option__first_paraph mat-18">
          <span class="bet-user__option__icon-info is-hidden-mobile">
            <ExclamationCircle v-show="false" color="white" class="exclamation-icon" />
          </span>
          Te proporcionamos 4 buenas <br class="is-hidden-mobile" />
          apuestas y tú eliges a cuál apostarle <br class="is-hidden-mobile" />
          y qué cantidad destinarle.
        </div>
        <div class="bet-user__option__second_paraph">¡Suerte!</div>
      </div>
      <div class="bet-user__option">
        <button class="bet-user__option__button" @click="nextStep('premium')">Usuario Premium $99 al mes</button>
        <div class="bet-user__option__first_paraph">
          GolStats por medio de algoritmos te ofrece las mejores apuestas. No solo te sugerimos a cuáles apostarle si no
          también qué cantidad apostarle a cada una para
          <span style="font-family: Avenir-Pro-Bold;"> maximizar tu rendimiento.</span>
        </div>
        <div class="bet-user__option__second_paraph">
          ¡Creemos en la suerte, pero confiamos más en la matemática y estadística!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ExclamationCircle from '@/components/Elements/icons/ExclamationCircle.vue';

import { clickBtnCamnioUsuarioPremium, clickBtnCaminoUsuarioFree } from '@/utils/analytics';

export default {
  name: 'SetBetUser',
  components: {
    ExclamationCircle,
  },
  data() {
    return {
      userTypes: {
        free: 1,
        premium: 2,
      },
    };
  },
  computed: {
    ...mapGetters('tips', ['getBetAmount']),
    ...mapGetters('loginGeneral', ['isLoggedIn', 'getPremiumAccount', 'getIsGolstats']),
    betAmount: {
      get: function() {
        return this.getBetAmount;
      },
      set: function(newVal) {
        const positiveRegex = new RegExp(/^[1-9]\d*$/);
        if (positiveRegex.test(newVal)) {
          this.setBetAmount(newVal);
        } else {
          this.$refs['input'].value = this.betAmount;
        }
      },
    },
  },
  methods: {
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapMutations('tips', ['setBetAmount', 'setUserType']),
    ...mapActions('alertGeneral', ['setAlert']),
    nextStep(userType) {
      this.setUserType(this.userTypes[userType]);
      if (userType === 'premium' && !this.isLoggedIn) {
        clickBtnCamnioUsuarioPremium(this.getPremiumAccount, this.getIsGolstats);
        this.SET_AUTH_COMPONENT('Login');
      } else {
        clickBtnCaminoUsuarioFree(this.getPremiumAccount, this.getIsGolstats);
        if (Number(this.betAmount) >= 50) {
          this.$router.push('/choose-the-risk');
        } else {
          this.setAlert({
            type: 'error',
            message: 'El monto mínimo de apuesta es $50.',
            duration: 3000,
            passwordInvalid: true,
          });
        }
      }
    },
    validateAmount() {
      if (Number(this.betAmount) < 50) {
        this.setBetAmount(100);
        this.$refs['input'].value = 100;
      }
    },
    selectAllAmount() {
      this.$refs.input.select();
    },
  },
};
</script>

<style lang="scss" scoped>
.bet-user {
  &__input {
    align-items: flex-start;
    display: flex;
    padding: 0px 30px 2px 0px;
    padding-right: 634px;
    flex-flow: column;
    &__title {
      font-family: Circular-Std-Bold;
      font-size: 10px;
      letter-spacing: 0.17px;
      text-transform: uppercase;
      color: #fff;
    }
    &__box {
      display: flex;
      font-family: Avenir-Pro-Bold;
      font-size: 14px;
      letter-spacing: -0.11px;
      color: #fff;
      border: solid 1px #fff;
      width: 180px;
      height: 30px;
      margin: 5px 0 0;
      padding: 3px 52px 0 20px;
      border-radius: 11px;
      input {
        width: 70px;
        background-color: transparent;
        font-family: Avenir-Pro-Bold;
        outline: none;
        border: none;
        color: #fff;
        &:focus {
          border: none;
          outline: none;
        }
      }
      span:first-child {
        padding-top: 3px;
      }
      span:last-child {
        font-family: Avenir-Regular;
        font-size: 13px;
        line-height: 2.1;
        letter-spacing: -0.09px;
        color: #fff;
      }
    }
  }

  &-container {
    padding: 45px 0px 1rem 0px;
    display: flex;
    flex-flow: column;
    justify-content: start;
    align-items: center;
    background: url('https://az755631.vo.msecnd.net/golstats-bets/set-bet-page.jpg') center/cover no-repeat;
    min-height: 100%;
    height: max-content;
  }
  &__title {
    font-family: Circular-Std-Bold;
    font-size: 25px;
    letter-spacing: -1.36px;
    margin: 20px;
    color: #fff;
    padding: 5px 127px;
    border-radius: 13px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(225, 225, 225, 0.14);
  }

  &__body {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: 13px;
    padding: 20px 35px;
    border: solid 1px #4c5e3c;
    background-color: rgba(29, 29, 29, 0.2);
  }
  &__option {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 245px;
    margin: 0px 10px;
    &__icon-info {
      width: 15px;
      position: absolute;
      top: -15px;
      left: -15px;
    }
    &__button {
      margin-bottom: 25px;
      padding: 5px 56px 6px;
      border-radius: 12px;
      border: solid 3px #c9e8af;
      background-color: transparent;
      font-family: Circular-Std;
      font-size: 15px;
      letter-spacing: -0.12px;
      color: #ffffff;
      outline: none;
      cursor: pointer;
      &:hover {
        background-color: #b9e097;
      }
    }
    &__first_paraph {
      position: relative;
      &.mat-18 {
        margin-top: 18px;
      }
    }
    &__first_paraph,
    &__second_paraph {
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      font-family: Avenir-Regular;
      width: 100%;
      font-size: 15px;
      text-align: start;
      line-height: 1.41;
      letter-spacing: -0.75px;
      color: #ffffff;
    }
    &__second_paraph {
      padding-top: 10px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .bet-user {
    &-container {
      padding: 25px 0px 1rem 0px;
      background: url('https://az755631.vo.msecnd.net/golstats-bets/set-bet-mobile.jpg') center/cover no-repeat;
    }
    &__title {
      margin: 20px 10%;
      padding: 10px;
    }
    &__body {
      flex-flow: column;
      align-items: center;
      border: unset;
    }
    &__option {
      border-radius: 13px;
      border: solid 1px rgba(255, 255, 255, 0.43);
      background-color: rgba(29, 29, 29, 0.2);
      margin-bottom: 20px;
      &__button {
        width: 100%;
        margin: -2px 0px;
        margin-bottom: 0px;
      }
      &__first_paraph {
        &.mat-18 {
          margin-top: 0px;
        }
      }
      &__first_paraph,
      &__second_paraph {
        text-align: center;
        font-size: 12px;
        padding: 8px 15px;
      }
    }
  }
}
@media only screen and (min-width: 1440px) {
  .bet-user-container {
    zoom: 1.2;
  }
}
</style>
