<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="-1000 -1000 1000 1000"
    enable-background="new 0 0 1000 1000"
    xml:space="preserve"
  >
    <metadata>Svg Vector Icons : http://www.onlinewebfonts.com/icon</metadata>
    <g>
      <g transform="rotate(180)">
        <path
          :style="fillColor"
          d="M500,10C229.3,10,10,229.3,10,500c0,270.6,219.3,490,490,490s490-219.4,490-490C990,229.3,770.8,10,500,10z
          M500,910.1C273.5,910.1,89.9,726.5,89.9,500C89.9,273.4,273.5,89.8,500,89.8c226.5,0,410.1,183.7,410.1,
          410.2C910.2,726.5,726.5,910.1,500,910.1z"
        />
        <polygon :style="fillColor" points="552.1,606 572,195.8 428.9,195.8 448.9,606 " />
        <path
          :style="fillColor"
          d="M500.5,651.2c-47.9,0-81.5,33.5-81.5,82.5c0,47.1,32.6,82.4,79.6,82.4h1.9c48.9,0,80.6-35.3,80.6-82.4C580.1,
          684.7,548.4,651.2,500.5,651.2z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ExclamationCircle',
  props: {
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    fillColor() {
      return `fill:${this.color}`;
    },
  },
};
</script>

<style scoped></style>
